<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <form @submit.prevent="submit(v$.$invalid)" class="p-fluid" style="margin-left: 28%;margin-right: 28%;">
      <div class="p-field p-grid">
        <label for="id" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>设备编号
        </label>
        <div class="p-col">
          <InputText :disabled="$route.query.pid && $route.query.did" id="id" @change="isCodeExist=false"
                     v-model.trim="v$.form.did.$model" autofocus/>
          <small class="p-error" v-if="v$.form.did.$invalid && form.$submitted">请输入设备编号</small>
          <small class="p-error" v-if="isCodeExist && form.$submitted">设备编号已存在</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>设备名称
        </label>
        <div class="p-col">
          <InputText id="name" v-model.trim="v$.form.dname.$model"/>
          <small class="p-error" v-if="v$.form.dname.$invalid && form.$submitted">请输入设备名称</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="type" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>设备类型
        </label>
        <div class="p-col">
          <Dropdown :filter="true" id="type" optionLabel="name" optionValue="code" v-model="v$.form.type.$model"
                    :disabled="$route.query.pid && $route.query.did"
                    :options="devTypeList" placeholder="请选择设备类型" @change="device"/>
          <small class="p-error" v-if="v$.form.type.$invalid && form.$submitted">请选择设备类型</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="position" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>设备坐标
        </label>
        <div class="p-col">
          <div class="p-inputgroup">
            <InputText id="position" v-model.trim="v$.form.l_l_full.$model"/>
            <Button v-if="$route.query.action!='view'" label="坐标设置" @click="selectPosition()"
                    class="p-button-outlined p-button-secondary" style="color: #12A25D;margin-left: 10px;"/>
          </div>
          <small class="p-error" v-if="v$.form.l_l_full.$invalid && form.$submitted">请输入设备坐标</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="location" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>设备地点
        </label>
        <div class="p-col">
          <InputText id="location" :readonly="true" v-model.trim="v$.form.p_c_d_full.$model"/>
          <small class="p-error" v-if="v$.form.p_c_d_full.$invalid && form.$submitted">请输入设备地点</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="location" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
          <span style="color:red;margin-right: 16px;"></span>详细地点
        </label>
        <div class="p-col">
          <InputText v-model.trim="v$.form.address.$model"/>
          <small class="p-error" v-if="v$.form.address.$invalid && form.$submitted">请输入设备详细地点</small>
        </div>
      </div>
      <div v-if="haikang">
        <div class="p-field p-grid">
          <label for="location" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>设备序列号
          </label>
          <div class="p-col">
            <InputText v-model.trim="v$.form.hkSerialNum.$model"/>
            <small class="p-error" v-if="v$.form.hkSerialNum.$invalid && form.$submitted">请输入设备序列号</small>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>AppKey
          </label>
          <div class="p-col">
            <InputText id="hkAppKey" v-model.trim="v$.form.hkAppKey.$model"/>
            <small class="p-error" v-if="v$.form.hkAppKey.$invalid && form.$submitted">请输入appKey</small>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>AppSecret
          </label>
          <div class="p-col">
            <InputText id="hkAppSecret" v-model.trim="v$.form.hkAppSecret.$model"/>
            <small class="p-error" v-if="v$.form.hkAppSecret.$invalid && form.$submitted">请输入appSecret</small>
          </div>
        </div>
      </div>
      <div v-if="jifei">
        <div class="p-field p-grid">
          <label for="location" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>设备序列号
          </label>
          <div class="p-col">
            <InputText v-model.trim="v$.form.jfSerialNum.$model"/>
            <small class="p-error" v-if="v$.form.jfSerialNum.$invalid && form.$submitted">请输入设备序列号</small>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>账号
          </label>
          <div class="p-col">
            <InputText v-model.trim="v$.form.jfAccount.$model"/>
            <small class="p-error" v-if="v$.form.jfAccount.$invalid && form.$submitted">请输入账号</small>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>密码
          </label>
          <div class="p-col" style="position:relative">
            <InputText id="pwd" type='password' v-model.trim="v$.form.jfPassword.$model"/>
            <img v-if="eyes" style="position:absolute;top:0.5rem;right:1rem;width:1.5rem"
                 src="@/assets/images/displayeyes.png" alt="" @mousedown="showPwd">
            <img v-else style="position:absolute;top:0.5rem;right:1rem;width:1.5rem" src="@/assets/images/showeyes.png"
                 alt="" @mouseup="displayPwd">
            <small class="p-error" v-if="v$.form.jfPassword.$invalid && form.$submitted">请输入密码</small>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>Secret
          </label>
          <div class="p-col">
            <InputText v-model.trim="v$.form.jfSecret.$model"/>
            <small class="p-error" v-if="v$.form.jfSecret.$invalid && form.$submitted">请输入secret</small>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>ClientId
          </label>
          <div class="p-col">
            <InputText v-model.trim="v$.form.jfClientId.$model"/>
            <small class="p-error" v-if="v$.form.jfClientId.$invalid && form.$submitted">请输入clientId</small>
          </div>
        </div>
      </div>
      <div v-if="tuya">
        <div class="p-field p-grid">
          <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>AccessId
          </label>
          <div class="p-col">
            <InputText v-model.trim="v$.form.tuyaAccessId.$model"/>
            <small class="p-error" v-if="v$.form.tuyaAccessId.$invalid && form.$submitted">请输入accessld</small>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-fixed" style="width:150px;padding:0 4px;justify-content: flex-end">
            <span style="color:red;margin-right: 4px;">*</span>AccessSecret
          </label>
          <div class="p-col">
            <InputText v-model.trim="v$.form.tuyaAccessKey.$model"/>
            <small class="p-error" v-if="v$.form.tuyaAccessKey.$invalid && form.$submitted">请输入accessKey</small>
          </div>
        </div>
      </div>
      <Toolbar style="background: none; border: none;margin-left: 106px;">
        <template #left>
          <Button style="background: #12A25D;border-color: #12A25D" :label="$route.query.did?'修改':'创建'"
                  type="submit" class="p-mr-2"/>
          <Button style="color: #12A25D;border-color: #12A25D" label="返回" @click="back()"
                  class="p-mr-2 p-button-outlined"/>
        </template>
      </Toolbar>
    </form>
  </div>

  <Dialog v-model:visible="dialog.show" :style="{width: '800px',height:'568px','padding':'0px','overflow':'hidden'}"
          :header="getTitle()" :modal="true" class="p-fluid" @hide="hideDialog">
    <div class="p-inputgroup" style="width: 240px;position: fixed;left: 50px;top:100px;">
      <InputText v-model="mapKeywords" placeholder="请输入"/>
      <Button style="background: #12A25D;border-color: #12A25D" icon="pi pi-search" @click="searchMap()"/>
      <Button style="background: #12A25D;border-color: #12A25D" v-if="mapKeywords" icon="pi pi-times"
              @click="clearSearch()" class="p-button-secondary"/>
    </div>
    <iframe style="width:100%;height:420px;border: none;" src="/amap.html"></iframe>
    <Toolbar style="background: none; border: none;">
      <template #left>
        <Button style="color: #12A25D;border-color: #12A25D" label="清除坐标" @click="clear()"
                class="p-mr-2 p-button-outlined"/>
      </template>
      <template #right>
        <Button style="color: #12A25D;border-color: #12A25D" label="取消" @click="hideDialog()"
                class="p-mr-2 p-button-outlined"/>
        <Button style="background: #12A25D;border-color: #12A25D" label="确定" @click="selected()" class="p-mr-2"/>
      </template>
    </Toolbar>
  </Dialog>

  <Toast/>

</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  components: {},
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      eyes: true,
      haikang: false,
      jifei: false,
      tuya: false,
      form: {
        did: null,
        pid: null,
        dname: null,
        type: null,
        longtitude: null,
        latitude: null,
        l_l_full: null, // 经纬度显示字段，不传入后台
        country: null,
        province: null,
        city: null,
        district: null,
        p_c_d_full: null, // 省市区显示字段，不传入后台
        address: null,
        hkSerialNum: null,
        hkAppKey: null,
        hkAppSecret: null,
        jfSerialNum: null,
        jfAccount: null,
        jfPassword: null,
        jfSecret: null,
        jfClientId: null,
        $submitted: false,
        tuyaAccessId: null,
        tuyaAccessKey: null,
        opeType: null
      },
      selectedPosition: null,
      devTypeList: [
        { name: "摄像头-萤石", code: 0 },
        { name: "摄像头-极飞", code: 1 },
        { name: "摄像头-涂鸦", code: 2 }
      ],
      breadcrumb: {
        home: { label: "智能设备管理", to: "#" },
        items: [
          { label: "设备管理", to: "/device" },
          { label: "添加设备", to: "#" },
        ],
      },
      dialog: {
        show: false,
      },
      mapKeywords: null,
      isCodeExist: false,
    };
  },
  validations() {
    return {
      form: {
        did: {
          required,
        },
        dname: {
          required,
        },
        type: {
          required,
        },
        l_l_full: {
          required,
        },
        p_c_d_full: {
          required,
        }, // 省市区显示字段，不传入后台
        hkSerialNum: this.form.type == 0 ? { required } : {},
        hkAppKey: this.form.type == 0 ? { required } : {},
        hkAppSecret: this.form.type == 0 ? { required } : {},
        jfSerialNum: this.form.type == 1 ? { required } : {},
        jfAccount: this.form.type == 1 ? { required } : {},
        jfPassword: this.form.type == 1 ? { required } : {},
        jfSecret: this.form.type == 1 ? { required } : {},
        jfClientId: this.form.type == 1 ? { required } : {},
        tuyaAccessId: this.form.type == 2 ? { required } : {},
        tuyaAccessKey: this.form.type == 2 ? { required } : {},
        address: {},
      },
    };
  },

  created() {

    if (this.$route.query.pid && this.$route.query.did) {
      this.form.opeType = 'update'
      this.breadcrumb.items[1].label = '编辑设备'
      this.$http(
        "/device/get",
        "get",
        { pid: this.$route.query.pid, did: this.$route.query.did },
        (res) => {
          this.form = res.data
          this.form.l_l_full = res.data.longtitude + ',' + res.data.latitude
          this.form.p_c_d_full = res.data.province + res.data.city + res.data.district
          // if(res.data.pid === 'webcam'){
          //   this.form.type =0
          // }else if(res.data.pid === 'jf_webcam'){
          //   this.form.type =1
          // }else if()
          this.form.type = res.data.pid === 'webcam' ? 0 : (res.data.pid === 'jf_webcam' ? 1 : (res.data.pid === 'tuya_webcam') ? 2 : null)
          this.device()
          if (res.data.pid === 'webcam') {
            this.form.hkAppKey = res.data.confMap.appKey
            this.form.hkSerialNum = res.data.confMap.serial_num
            this.form.hkAppSecret = res.data.confMap.appSecret
          } else if (res.data.pid === 'jf_webcam') {
            this.form.jfAccount = res.data.confMap.account
            this.form.jfSerialNum = res.data.confMap.jf_serial_num
            this.form.jfPassword = res.data.confMap.jfPassword
            this.form.jfClientId = res.data.confMap.secret
            this.form.jfClientId = res.data.confMap.clientId
          } else if (res.data.pid === 'tuya_webcam') {
            this.form.tuyaAccessId = res.data.confMap.accessId
            this.form.tuyaAccessKey = res.data.confMap.accessKey
          }

        }
      )
    }
  },
  mounted() {
    let me = this;
    top["setSelectedPosition"] = function(pos) {
      me.selectedPosition = pos;
      me.form.l_l_full = pos.lon + ", " + pos.lat;
      me.form.p_c_d_full = pos.province + pos.city + pos.district;
      me.form.province = pos.province;
      me.form.city = pos.city;
      me.form.district = pos.district;
      me.form.address = pos.address;
    };
  },
  methods: {
    showPwd() {
      this.eyes = false;
      document.getElementById("pwd").type = "text";
    },
    displayPwd() {
      this.eyes = true;
      document.getElementById("pwd").type = "password";
    },
    device() {
      if (this.form.type == 0) {
        this.haikang = true;
        this.jifei = false;
        this.tuya = false;
      }
      if (this.form.type == 1) {
        this.jifei = true;
        this.haikang = false;
        this.tuya = false;
      }
      if (this.form.type == 2) {
        this.tuya = true;
        this.haikang = false;
        this.jifei = false;
      }
    },
    searchMap() {
      top["searchMap"](this.mapKeywords);
    },
    clearSearch() {
      this.mapKeywords = null;
      top["clearSearch"]();
    },
    clear() {
      this.selectedPosition = null;
      this.mapKeywords = null;
      this.$toast.add({
        severity: "success",
        summary: "消息",
        detail: "已清空所选位置！",
        life: 3000,
      });
    },
    selected() {
      if (!this.selectedPosition) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "请选择一个经纬度！",
          life: 3000,
        });
        return;
      }
      this.dialog.show = false;
    },
    getTitle() {
      if (this.selectedPosition) {
        return (
          "坐标位置 - " +
          this.selectedPosition.province +
          this.selectedPosition.city +
          this.selectedPosition.district +
          (this.selectedPosition.address ? this.selectedPosition.address : "") +
          "（" +
          this.selectedPosition.lon +
          "," +
          this.selectedPosition.lat +
          "）"
        );
      } else {
        return "坐标位置";
      }
    },
    back() {
      this.$router.push("/device");
    },
    selectPosition() {
      this.dialog.show = true;
    },
    hideDialog() {
      this.dialog.show = false;
    },
    submit(isInvalid) {
      if (this.$route.query.pid && this.$route.query.did) {
        this.form.opeType = 'update'
      } else {
        this.form.opeType = ' add'
      }
      if (this.form.l_l_full) {
        this.form.latitude = this.form.l_l_full.split(',')[1]
        this.form.longtitude = this.form.l_l_full.split(',')[0]
      }
      let me = this;

      this.form.$submitted = true;
      if (isInvalid) {
        return;
      }
      console.log(this.form)

      if (!(this.form.longtitude && this.form.latitude)) {
        // 处理字段内容
        this.form.longtitude = this.selectedPosition.lon;
        this.form.latitude = this.selectedPosition.lat;
      }
      delete this.form.$submitted
      this.$axios.post('/device/save', this.form).then((res) => {
        if (!res) {
          this.$toast.add({
            severity: "warn",
            summary: "提示",
            detail: "设备编号已存在",
            life: 3000,
          });
          return
        }
        if (res.success) {
          this.$toast.add({
            severity: "success",
            summary: "成功",
            detail: "设备添加成功！",
            life: 3000,
          });
          this.back();
        } else {
          if (res.code === "760987") {
            this.isCodeExist = true;
          }
        }
      })

      // this.$http(
      //     "/device/save",
      //     "post",
      //     this.form,
      //     () => {
      //       me.$toast.add({
      //         severity: "success",
      //         summary: "成功",
      //         detail: "设备添加成功！",
      //         life: 3000,
      //       });
      //       me.back();
      //     },
      //     (err) => {
      //       if (err.code == "760987") {
      //         this.isCodeExist = true;
      //       }
      //     }
      // );
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
